







































































import { Component, Vue } from 'vue-property-decorator'
import WidgetTitle from '@/components/common/WidgetTitle.vue'
import BezeButton from '@/components/common/BezeButton.vue'
import BezeInput from '@/components/common/BezeInput.vue'
import { mask } from 'vue-the-mask'
import { ILoginData } from '@/shared/models/auth'

const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/

@Component({
  components: {
    WidgetTitle,
    BezeButton,
    BezeInput
  },
  directives: {
    mask
  }
})
export default class Registration extends Vue {
  isBtnLoading = false
  confirmPassword = ''
  userData: ILoginData = {
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    address: ''
  }

  mounted(): void {
    this.$store.dispatch('toggleModal', { open: false, modalName: 'loginModal' })
  }

  get authErrorMsg(): string {
    return this.$store.getters.authErrorMsg
  }

  get isPasswordConfirmed(): boolean {
    return this.userData.password === this.confirmPassword
  }

  get isFormCorrect(): boolean {
    return this.userData.phone?.length > 7 &&
      this.userData.firstName?.length > 2 &&
      this.userData.lastName?.length > 2 &&
      this.userData.password?.length > 5 &&
      this.isPasswordConfirmed &&
      emailRegExp.test(this.userData.email as string)
  }

  registerNewUser(): void {
    this.isBtnLoading = true
    this.$store.dispatch(
      'register',
      this.userData
    ).then(() => {
      const { email, password } = this.userData
      this.$toasted.success('Выполняем вход...')
      this.$store.dispatch(
        'login',
        { username: email, password }
      ).then(() => {
        this.$router.push('/account')
      })
    }).finally(() => {
      this.isBtnLoading = false
    })
  }
}
